body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

ul.pagination {
  display: flex;
  justify-content: center;
}
ul.pagination .page-item {
  width: auto;
  display: block;
  padding: 0 10px;
}
ul.pagination .page-item .page-link{
  cursor: pointer;
}
li.page-item a.page-link.active{
  padding:5px 10px;
}

.cursor-pointer{
  cursor: pointer!important;
}
.cursor-menu{
  cursor: context-menu;
}
